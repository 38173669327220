import React, { createContext, useContext } from "react"
import { useActiveDoc } from "./ActiveDocProvider"

const TranslationContext = createContext()

export const useTranslation = () => useContext(TranslationContext)

const TranslationProvider = ({ children, i18n }) => {
    const { activeDoc } = useActiveDoc()

    const t = (key, lang = null) => (i18n[lang ?? activeDoc.lang] && i18n[lang ?? activeDoc.lang][key]) ?? key

    return (
        <TranslationContext.Provider value={{ t }}>
            {children}
        </TranslationContext.Provider>
    )
}

export default TranslationProvider
